import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../../components/layout'
import Hero from '../../../components/heroSubpage'
import SEO from '../../../components/seo'

const ProdLevelsTemplate = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="12">
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                />

                <div
                className="mt-3 richtext images divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>              
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default ProdLevelsTemplate

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(

      frontmatter: { name: { eq: "product-levels" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        openingpara
        alttext
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
      html
    }
  }
`
